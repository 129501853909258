import { RouteObject } from "react-router-dom";

import { RotasLogadas } from "../Auth/Components/Logged";

import { Inicial } from "./Pages/Inicial";

export const mainRoute: RouteObject[] = [
  {
    path: "/",
    element: <RotasLogadas />,
    children: [
      {
        path: "",
        element: <Inicial />,
      },
    ],
  },
];

import "./Atalhos.css";

import iconHome from "./Images/icon-casa-nav.png";
import iconConfig from "./Images/icon-configuracoes-nav.png";
import iconExit from "./Images/icon-sair-nav.png";
import iconLightwall from "./Images/icon-lightwall-nav.png";

import { SyntheticEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { usuario } from "../../Configs/Usuario";

import { toggleActive, toggleList } from "../../Helpers/toggle";
import { fetchUnidades } from "../../Helpers/fetch";
import { Spinner } from "../Spinner/Spinner";
import { pcp } from "../../Configs/Axios";
import { AxiosError } from "axios";

export const Atalhos = () => {
  const navigate = useNavigate();

  let tabs: string[] = [];
  usuario.producao
    ? (tabs = ["Usuários", "Baterias", "Configurações"])
    : (tabs = ["Usuários"]);

  const [tabsActive, setTabs] = useState<Array<boolean>>([true, false, false]);
  const [active, setActive] = useState<boolean>(false);
  const [spin, setSpin] = useState<boolean>(false);

  const { data } = useQuery({ queryKey: ["unidades"], queryFn: fetchUnidades });

  return (
    <>
      <section id="atalhos">
        <a href="/">
          <button type="button" className="btn-atalho">
            <img src={iconHome} alt="Ícone de Home" />
          </button>
        </a>

        <a
          href="/auth/apps"
          className={`${usuario.unidade !== "PT" && "unreachable"}`}
        >
          <button type="button" className="btn-atalho">
            <img src={iconLightwall} alt="Logo da Lightwall" />
          </button>
        </a>

        <button
          onClick={() => toggleActive(setActive, active)}
          type="button"
          className={`btn-atalho ${
            usuario.nivel !== "administrador" && "unreachable"
          }`}
        >
          <img src={iconConfig} alt="Ícone de Configuração" />
        </button>

        <button onClick={handleSaida} type="button" className="btn-atalho">
          <img src={iconExit} alt="Ícone de Saída" />
        </button>

        {process.env.NODE_ENV === "development" && (
          <button type="button" onClick={sincronizar} className="btn-atalho">
            &#128259;
          </button>
        )}
      </section>
      <section
        className={`center ${active ? "visible" : "invisible"}`}
        id="config"
      >
        <section className="nav">
          <section className="tabs">
            {tabs.map((text, idx) => {
              return (
                <button
                  key={text}
                  onClick={() => toggleList(setTabs, tabs, text)}
                  className={`tab ${tabsActive[idx] ? "in" : "out"}`}
                  type="button"
                >
                  {text}
                </button>
              );
            })}
          </section>
          <button type="button" onClick={() => toggleActive(setActive, active)}>
            &#x2715;
          </button>
        </section>
        <section className="cont">
          <form
            id="form-config"
            className={`form ${!tabsActive[0] && "invisible"}`}
            onSubmit={cadastroColaborador}
          >
            <section className="input">
              <label htmlFor="email">E-mail:</label>
              <input
                type="email"
                name="email"
                id="email"
                required
                autoComplete="email"
              />
            </section>
            <section className="input">
              <label htmlFor="nome">Nome:</label>
              <input type="text" name="nome" id="nome" required />
            </section>
            <section className="input">
              <label htmlFor="nivel">Nível:</label>
              <select name="nivel" id="nivel">
                <option value="visualizador">Visualizador</option>
                <option value="apontador">Apontador</option>
                <option value="administrador">Administrador</option>
              </select>
            </section>
            <section className="input">
              <label htmlFor="unidade">Unidade:</label>
              <select name="unidade" id="unidade">
                {data && data.length > 1 && (
                  <option value="PT">Lightwall Participações</option>
                )}
                {data?.map((unidade) => (
                  <option key={unidade.sigla} value={unidade.sigla}>
                    Lightwall {unidade.descricao}
                  </option>
                ))}
              </select>
            </section>
            <section className="input btn-form">
              <fieldset>
                <legend>Setores:</legend>

                <section className="setores">
                  <section
                    className={`setor ${!usuario.producao && "unreachable"}`}
                  >
                    <label htmlFor="producao">Produção</label>
                    <input type="checkbox" name="producao" id="producao" />
                  </section>
                  <section
                    className={`setor ${!usuario.qualidade && "unreachable"}`}
                  >
                    <label htmlFor="qualidade">Qualidade</label>
                    <input type="checkbox" name="qualidade" id="qualidade" />
                  </section>
                  <section
                    className={`setor ${!usuario.logistica && "unreachable"}`}
                  >
                    <label htmlFor="logistica">Logística</label>
                    <input type="checkbox" name="logistica" id="logistica" />
                  </section>
                </section>
              </fieldset>
            </section>
            <button className="btn-form" type="submit">
              Cadastrar
            </button>
          </form>
          <form
            id="form-config"
            className={`form ${!tabsActive[1] && "invisible"}`}
            onSubmit={cadastrarBaterias}
          >
            <section className="input">
              <label htmlFor="prefixo">Prefixo das Baterias:</label>
              <input type="text" name="prefixo" id="prefixo" />
            </section>
            <section className="input">
              <label htmlFor="quantidade">
                Quantidade de Baterias (Total):
              </label>
              <input type="number" min={1} name="quantidade" id="quantidade" />
            </section>
            <section className="input">
              <label htmlFor="setecinco_bat">
                Quantidade de Painéis 75mm (Por Bateria):
              </label>
              <input
                type="number"
                min={1}
                name="setecinco_bat"
                id="setecinco_bat"
              />
            </section>
            <section className="input">
              <label htmlFor="setecinco_tur">
                Quantidade Máxima de Baterias 75mm (Por Turno):
              </label>
              <input
                type="number"
                min={1}
                name="setecinco_tur"
                id="setecinco_tur"
              />
            </section>
            <section className="input">
              <label htmlFor="noventa_bat">
                Quantidade de Painéis 90mm (Por Bateria):
              </label>
              <input
                type="number"
                min={1}
                name="noventa_bat"
                id="noventa_bat"
              />
            </section>
            <section className="input">
              <label htmlFor="noventa_tur">
                Quantidade Máxima de Baterias 90mm (Por Turno):
              </label>
              <input
                type="number"
                min={1}
                name="noventa_tur"
                id="noventa_tur"
              />
            </section>
            <section className="input">
              <label htmlFor="centovinte_bat">
                Quantidade de Painéis 120mm (Por Bateria):
              </label>
              <input
                type="number"
                min={1}
                name="centovinte_bat"
                id="centovinte_bat"
              />
            </section>
            <section className="input">
              <label htmlFor="centovinte_tur">
                Quantidade Máxima de Baterias 120mm (Por Turno):
              </label>
              <input
                type="number"
                min={1}
                name="centovinte_tur"
                id="centovinte_tur"
              />
            </section>
            <section className="input btn-form">
              <label htmlFor="extensores">
                Quantidade Máxima de Baterias com Extensores (Por Turno):
              </label>
              <input type="number" min={1} name="extensores" id="extensores" />
            </section>
            <button type="submit" className="btn-form">
              Atualizar
            </button>
          </form>
          <form
            id="form-config"
            className={`form ${!tabsActive[2] && "invisible"}`}
            onSubmit={configuracoes}
          >
            <section className="input btn-form">
              <label htmlFor="turnos">Quantidade de Turnos:</label>
              <input type="number" min={1} max={3} name="turnos" id="turnos" />
            </section>
            <section className="input btn-form">
              <fieldset>
                <legend>Dias de Produção:</legend>

                <section className="setores">
                  <section className="setor">
                    <label htmlFor="domingo">Domingo</label>
                    <input type="checkbox" name="domingo" id="domingo" />
                  </section>
                  <section className="setor">
                    <label htmlFor="segunda">Segunda</label>
                    <input type="checkbox" name="segunda" id="segunda" />
                  </section>
                  <section className="setor">
                    <label htmlFor="terca">Terça</label>
                    <input type="checkbox" name="terca" id="terca" />
                  </section>
                  <section className="setor">
                    <label htmlFor="quarta">Quarta</label>
                    <input type="checkbox" name="quarta" id="quarta" />
                  </section>
                  <section className="setor">
                    <label htmlFor="quinta">Quinta</label>
                    <input type="checkbox" name="quinta" id="quinta" />
                  </section>
                  <section className="setor">
                    <label htmlFor="sexta">Sexta</label>
                    <input type="checkbox" name="sexta" id="sexta" />
                  </section>
                  <section className="setor">
                    <label htmlFor="sabado">Sábado</label>
                    <input type="checkbox" name="sabado" id="sabado" />
                  </section>
                </section>
              </fieldset>
            </section>
            <button type="submit" className="btn-form">
              Atualizar
            </button>
          </form>
        </section>
        <Spinner active={spin} />
      </section>
    </>
  );

  function handleSaida() {
    localStorage.clear();

    navigate("/auth");
  }

  async function cadastro(uri: string, data: Record<string, unknown>) {
    setSpin(true);

    try {
      const newData = await pcp.post(uri, data);
      if (newData.status !== 201) {
        setSpin(false);
        return alert("Dados inválidos!");
      }

      setSpin(false);
      return alert("Item atualizado com sucesso!");
    } catch (error) {
      setSpin(false);

      if (error instanceof AxiosError)
        if (error.response) return alert(JSON.stringify(error.response.data));

      return alert(`Ocorreu um erro ao processar atualização: ${error}`);
    }
  }

  async function cadastroColaborador(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const novoColaborador = {
      email: event.currentTarget.email.value,
      nome: event.currentTarget.nome.value,
      nivel: event.currentTarget.nivel.value,
      unidade_sigla: event.currentTarget.unidade.value,
      producao: event.currentTarget.producao.checked,
      qualidade: event.currentTarget.qualidade.checked,
      logistica: event.currentTarget.logistica.checked,
    };

    return await cadastro("/cadastro", novoColaborador);
  }

  async function cadastrarBaterias(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const configuracoesBaterias = {
      prefixo: event.currentTarget.prefixo.value,
      quantidade: event.currentTarget.quantidade.value,
      setecinco_bat: event.currentTarget.setecinco_bat.value,
      setecinco_tur: event.currentTarget.setecinco_tur.value,
      noventa_bat: event.currentTarget.noventa_bat.value,
      noventa_tur: event.currentTarget.noventa_tur.value,
      centovinte_bat: event.currentTarget.centovinte_bat.value,
      centovinte_tur: event.currentTarget.centovinte_tur.value,
      extensores: event.currentTarget.extensores.value,
    };

    return await cadastro("/cadastro/baterias", configuracoesBaterias);
  }

  async function configuracoes(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const configuracoes = {
      turnos: event.currentTarget.turnos.value,
      domingo: event.currentTarget.domingo.checked,
      segunda: event.currentTarget.segunda.checked,
      terca: event.currentTarget.terca.checked,
      quarta: event.currentTarget.quarta.checked,
      quinta: event.currentTarget.quinta.checked,
      sexta: event.currentTarget.sexta.checked,
      sabado: event.currentTarget.sabado.checked,
    };

    return await cadastro("/cadastro/configuracoes", configuracoes);
  }

  async function sincronizar(event: SyntheticEvent) {
    event.preventDefault();
    setSpin(true);

    try {
      await pcp
        .post("/dispatch/omie/sincronizar_produtos")
        .then(await pcp.post("/dispatch/omie/sincronizar_pedidos"));

      setSpin(false);
      return alert("Dados sincronizados ao Omie com sucesso!");
    } catch (error) {
      setSpin(false);

      if (error instanceof AxiosError)
        if (error.response) return alert(JSON.stringify(error.response.data));

      return alert(error);
    }
  }
};

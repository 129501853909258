import { useState } from "react";
import { Splash } from "../../../Components/Splash/Splash";
import { FluxoEstoque } from "../Graficos/FluxoEstoque";

export const SplashLogistica = () => {
  const [spin, setSpin] = useState<boolean>(false);

  return (
    <Splash
      ativo={[false, false, true]}
      spin={spin}
      grafico={
        <FluxoEstoque
          dados={{
            dias: [],
            qtdAEntregar: [],
            qtdEstoqueProg: [],
            qtdDeficitProd: [],
          }}
        />
      }
      setor="logistica"
      caminhos={["pedidos"]}
    />
  );
};

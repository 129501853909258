import "./Splash.css";

import { Navegacao } from "../Navegacao/Navegacao";

import { Atalhos } from "../Atalhos/Atalhos";
import { Spinner } from "../Spinner/Spinner";

interface Props {
  ativo: boolean[];
  spin: boolean;
  grafico: React.ReactElement;
  setor: string;
  caminhos: string[];
}

export const Splash = (props: Props) => {
  return (
    <>
      <Navegacao ativo={props.ativo} />
      <section id="main">
        <section id="content">
          <section id="main-content">
            {props.grafico}
            <article className="botoes-paginas">
              {props.caminhos.map((caminho) => {
                return (
                  <a key={caminho} href={`/${props.setor}/${caminho}`}>
                    <button className="nav-paginas">
                      {caminho.toUpperCase()}
                    </button>
                  </a>
                );
              })}
            </article>
          </section>
        </section>
        <Atalhos />
        <Spinner active={props.spin} />
      </section>
    </>
  );
};

import "../Login.css";

import { useState } from "react";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

import { pcp } from "../../../../Configs/Axios";

import { Spinner } from "../../../../Components/Spinner/Spinner";

export const Login = () => {
  const [spin, setSpin] = useState<boolean>(false);
  const [token, setToken] = useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <section id="main">
      <section id="content">
        <section id="main-content">
          <form onSubmit={handleSubmit} id="form">
            <section className="input">
              <label htmlFor="email">E-mail:</label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Insira seu e-mail corporativo"
                autoComplete="email"
                required
              />
            </section>

            <section className={`input ${token ? "" : "token"}`}>
              <label htmlFor="token">Token:</label>
              <input
                name="token"
                id="token"
                placeholder="Insira o token enviado a seu e-mail"
                required={token}
              />
            </section>
            <button type="submit">Acessar</button>
          </form>
          <Spinner active={spin} />
        </section>
      </section>
    </section>
  );

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setSpin(true);

    if (!token) {
      try {
        const validacao = await pcp.post("/login", {
          email: event.currentTarget.email.value,
        });
        if (validacao.status !== 204) return alert("E-mail inválido!");

        setSpin(false);
        setToken(true);

        return alert("Um e-mail foi enviado com o token de acesso ao sistema!");
      } catch (error) {
        setSpin(false);

        if (error instanceof AxiosError)
          if (error.response) return alert(JSON.stringify(error.response.data));

        return alert(
          `Ocorreu um erro ao tentar acessar dados do email: ${error}`
        );
      }
    } else {
      localStorage.clear();

      try {
        const validacao = await pcp.post("/login/token", {
          email: event.currentTarget.email.value,
          token: event.currentTarget.token.value,
        });
        if (validacao.status !== 200)
          return alert("Dados de usuário inválidos!");

        setSpin(false);
        localStorage.setItem("usuario", JSON.stringify(validacao.data));

        alert("Usuário logado com sucesso!");
        if (validacao.data.unidade.toLowerCase() === "pt") {
          navigate("/auth/apps");
        } else {
          navigate("/");
        }
      } catch (error) {
        setSpin(false);

        if (error instanceof AxiosError)
          if (error.response) return alert(JSON.stringify(error.response.data));

        return alert(
          `Ocorreu um erro ao tentar acessar dados do token: ${error}`
        );
      }
    }
  }
};

import iconProducao from "../Images/icon-producao.png";
import iconQualidade from "../Images/icon-qualidade.png";
import iconLogistica from "../Images/icon-logistica.png";

import { BotaoInicial } from "../Components/Botao-Inicial";
import { Atalhos } from "../../../Components/Atalhos/Atalhos";

import { usuario } from "../../../Configs/Usuario";

export const Inicial = () => {
  return (
    <section id="main">
      <section id="content">
        <section id="main-content">
          <BotaoInicial
            active={usuario.producao}
            href="/producao"
            class="producao"
            imgURL={iconProducao}
            title="Produção"
            alt="Ícone de Fábrica"
          />
          <BotaoInicial
            active={usuario.qualidade}
            href="/qualidade"
            class="qualidade"
            imgURL={iconQualidade}
            title="Qualidade"
            alt="Ícone de Bottom de Premiação"
          />
          <BotaoInicial
            active={usuario.logistica}
            href="/logistica"
            class="logistica"
            imgURL={iconLogistica}
            title="Logística"
            alt="Ícone de Caminhão"
          />
        </section>
      </section>
      <Atalhos />
    </section>
  );
};

import "../Pedido.css";

import { SyntheticEvent, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { Card } from "../../../Components/Card/Card";
import { Spinner } from "../../../Components/Spinner/Spinner";
import { Legenda } from "../../../Components/Legenda/Legenda";
import { Atalhos } from "../../../Components/Atalhos/Atalhos";
import { Navegacao } from "../../../Components/Navegacao/Navegacao";

import { fetchColetas, fetchPedido } from "../Helpers/fetch";
import { toggleActive } from "../../../Helpers/toggle";
import { pcp } from "../../../Configs/Axios";
import { AxiosError } from "axios";

export const ResumoPedido = () => {
  const [spin, setSpin] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const [colActive, setColActive] = useState<boolean>(false);
  const { numero_pedido } = useParams();

  const {
    status: statusPedido,
    data: pedido,
    isLoading: loadingPedido,
  } = useQuery({
    queryKey: ["pedido", numero_pedido],
    queryFn: () => fetchPedido(numero_pedido!),
    enabled: !!numero_pedido,
  });
  const {
    status: statusColeta,
    data: coletas,
    isLoading: loadingColetas,
  } = useQuery({
    queryKey: ["coletas", numero_pedido],
    queryFn: () => fetchColetas(numero_pedido!),
    enabled: !!numero_pedido,
  });

  return (
    <>
      <Navegacao ativo={[false, false, true]} />
      <section id="main">
        <section id="content">
          <section id="main-content">
            <Legenda
              active={active}
              onToggle={() => toggleActive(setActive, active)}
              items={{
                "fat-pendente": "Faturamento Pendente",
                faturado: "Faturado",
                agendado: "Agendado",
                "coleta-parcial": "Coleta Parcial",
                coletado: "Coletado",
                "pag-pendente": "Pagamento Pendente",
                cancelado: "Cancelado",
              }}
            />
            <section className="focus">
              {loadingPedido ? (
                <Spinner active />
              ) : statusPedido === "success" ? (
                <>
                  <section id="pedido">
                    <section id="card-resumo">
                      <Card
                        class={pedido.status}
                        title={`Pedido ${pedido.numero_pedido}`}
                        subtitle="Produtos:"
                        content={pedido.produtos.map((produto) => {
                          return (
                            <section key={produto.descricao}>
                              {produto.descricao}: <br />
                              &emsp;Qtd Total: {produto.qtdTotal} <br />
                              &emsp;Qtd Pendente: {produto.qtdPendente} <br />
                              &emsp;Qtd Parcial: {produto.qtdParcial}
                              <br />
                              <br />
                            </section>
                          );
                        })}
                        icons={[{ href: "/logistica/pedidos", icon: "21d0" }]}
                      />
                    </section>
                    <button
                      className="add-coleta"
                      onClick={() => toggleActive(setColActive, colActive)}
                    >
                      Adicionar Coleta
                    </button>
                  </section>
                  <section id="detalhes-pedido">
                    {loadingColetas ? (
                      <Spinner active />
                    ) : statusColeta === "success" && coletas.length > 0 ? (
                      coletas?.map((coleta) => {
                        return (
                          <section key={coleta.codigo} id="ped">
                            <Card
                              class={coleta.status}
                              title={`Entrega ${new Date(
                                coleta.entrega
                              ).toLocaleDateString("pt-br", {
                                timeZone: "UTC",
                              })}`}
                              subtitle={`Pedido ${coleta.numero_pedido}`}
                              content={coleta.produtos.map((produto) => {
                                return (
                                  <section key={produto.descricao}>
                                    <strong style={{ fontWeight: "bold" }}>
                                      {produto.descricao}
                                    </strong>{" "}
                                    - {produto.quantidade}
                                    <br />
                                    <br />
                                  </section>
                                );
                              })}
                            />
                            <button
                              id={coleta.codigo.toString()}
                              onClick={handleDelete}
                            >
                              Deletar
                            </button>
                          </section>
                        );
                      })
                    ) : (
                      <article>Não há informações de coletas!</article>
                    )}
                  </section>
                </>
              ) : (
                <article>Não há informações de pedido!</article>
              )}
            </section>
          </section>
        </section>
        <Atalhos />
        <section className={`edicoes ${colActive && "active"}`}>
          <section>
            <h2>Adicionar Coleta:</h2>
            <form onSubmit={adicionarColeta} className="edit add-coleta">
              <label htmlFor="entrega">Entrega:</label>
              <input type="date" name="entrega" id="entrega" />
              <fieldset className="edit add-coleta">
                <legend>Produtos:</legend>

                {pedido?.produtos.map((produto) => {
                  let qtdColeta = 0;

                  if (coletas) {
                    const coletasProduto = coletas.filter((coleta) => {
                      coleta.produtos.some((produtoColeta) => {
                        return produtoColeta.descricao === produto.descricao;
                      });
                    });
                    coletasProduto.forEach((coleta) => {
                      coleta.produtos.forEach((produtoColeta) => {
                        if (produtoColeta.descricao === produto.descricao)
                          qtdColeta += produtoColeta.quantidade;
                      });
                    });
                  }

                  return (
                    <article key={produto.descricao}>
                      <h3>{produto.descricao}</h3>
                      <input
                        defaultValue={produto.qtdPendente - qtdColeta}
                        type="number"
                        name={produto.descricao}
                        className="quantidade"
                        min={0}
                        max={produto.qtdPendente - qtdColeta}
                      />
                    </article>
                  );
                })}
              </fieldset>
              <button type="submit">Adicionar</button>
            </form>
          </section>
          <button onClick={() => toggleActive(setColActive, colActive)}>
            <span className="icon">&#x2715;</span>
          </button>
        </section>
        <Spinner active={spin} />
      </section>
    </>
  );

  async function handleDelete(event: SyntheticEvent) {
    setSpin(true);

    try {
      await pcp.delete(`/logistica/pedidos/coleta/${event.currentTarget.id}`);

      setSpin(false);
      alert("Coleta deletada com sucesso!");
      window.location.reload();
    } catch (error) {
      setSpin(false);
      alert(`Ocorreu um erro ao deletar coleta: ${error}`);
    }
  }
  async function adicionarColeta(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setSpin(true);

    const target = event.currentTarget;

    const produtos: Produto[] = [];
    pedido?.produtos.forEach((produto) => {
      if (target[`${produto.descricao}`].value > 0)
        produtos.push({
          descricao: produto.descricao,
          quantidade: target[`${produto.descricao}`].value,
        });
    });

    const corpoColeta = {
      entrega: target.entrega.value,
      codigo_pedido: pedido?.codigo,
      numero_pedido: pedido?.numero_pedido,
      produtos: produtos,
    };

    console.log(corpoColeta);
    try {
      const novaColeta = await pcp.post(
        "/logistica/pedidos/coleta",
        corpoColeta
      );
      if (novaColeta.status !== 201) {
        setSpin(false);
        return alert("Dados inválidos!");
      }

      setSpin(false);
      window.location.reload();
      return alert("Coleta adicionada com sucesso!");
    } catch (error) {
      setSpin(false);

      if (error instanceof AxiosError)
        if (error.response) return alert(JSON.stringify(error.response.data));

      return alert(`Ocorreu um erro ao adicionar a coleta: ${error}`);
    }
  }
};

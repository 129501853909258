import ApexCharts from "react-apexcharts";

interface Props {
  dados: DadosFluxoEstoque;
}

export function FluxoEstoque(props: Props) {
  const options = {
    chart: {
      id: "basic-bar",
    },

    legend: {
      show: true,
      fontSize: "20px",
      width: 700,
      labels: {
        color: "#ffffff",
        useSeriesColors: true,
      },
      itemMargin: {
        horizontal: 15,
      },
    },
    title: {
      text: "FLUXO DE ESTOQUE",
      style: {
        color: "#ffffff",
        fontSize: "10px",
      },
    },

    xaxis: {
      categories: props.dados.dias,
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: "#ffffff",
      },
      labels: {
        style: {
          colors: "#ffffff",
        },
      },
      title: {
        text: "Tempo (Dia)",
        align: "left",
        style: {
          color: "#FFFFFF",
        },
      },
    },
    stroke: {
      width: [2, 2, 2],
    },
    grid: {
      borderColor: "#1e1e1c5c",
    },
    colors: ["#B6B92F", "#42B92F", "#B92F2F"],
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#ffffff",
        },
        labels: {
          style: {
            colors: "#ffffff",
          },
        },
        title: {
          text: "Qtd. (Painéis)",
          style: {
            color: "#FFFFFF",
          },
        },
      },
    ],
  };

  const series = [
    {
      name: "Painéis a Entregar",
      data: props.dados.qtdAEntregar,
      colors: ["#000000"],
    },
    {
      name: "Estoque Programado",
      data: props.dados.qtdEstoqueProg,
    },
    {
      name: "Déficit de Produção",
      data: props.dados.qtdDeficitProd,
    },
  ];

  return (
    <article className="grafico">
      <ApexCharts
        series={series}
        options={options}
        type="line"
        width={"100%"}
        height={"100%"}
      />
    </article>
  );
}

import { RouteObject } from "react-router-dom";

import { RotasLogadas } from "../Auth/Components/Logged";

import { SplashLogistica } from "./Pages/Splash";
import { Pedidos } from "./Pages/Pedidos";
import { ResumoPedido } from "./Pages/Resumo-Pedido";

export const logisticaRoute: RouteObject[] = [
  {
    path: "/logistica",
    element: <RotasLogadas />,
    children: [
      {
        path: "",
        element: <SplashLogistica />,
      },
      {
        path: "pedidos",
        children: [
          {
            index: true,
            element: <Pedidos />,
          },
          {
            path: ":numero_pedido",
            element: <ResumoPedido />,
          },
        ],
      },
    ],
  },
];

import { pcp } from "../../../Configs/Axios";

export const fetchPedidos = (): Promise<Pedido[]> =>
  pcp.get("/logistica/pedidos").then((result) => result.data);

export const fetchPedido = (numero_pedido: string): Promise<Pedido> =>
  pcp
    .get(`/logistica/pedidos/${numero_pedido}`)
    .then((result) => result.data[0]);

export const fetchColetas = (numero_pedido: string): Promise<Coleta[]> =>
  pcp
    .get(`/logistica/pedidos/coleta/${numero_pedido}`)
    .then((result) => result.data);

export const fetchProgColetas = (dia: string): Promise<ProgColeta[]> =>
  pcp
    .get(`/logistica/pedidos/coleta/programacao/${dia}`)
    .then((result) => result.data);

import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { SyntheticEvent, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { pcp } from "../../../../Configs/Axios";

import { Card } from "../../../../Components/Card/Card";
import { Spinner } from "../../../../Components/Spinner/Spinner";
import { fetchUnidades } from "../../../../Helpers/fetch";

export const Aplicativos = () => {
  const navigate = useNavigate();

  const [spin, setSpin] = useState<boolean>(false);
  const { data } = useQuery({ queryKey: ["unidades"], queryFn: fetchUnidades });

  return (
    <section id="main">
      <section id="content">
        <section id="main-content">
          {data?.map((unidade) => (
            <Card
              key={unidade.sigla}
              title={`PCP LW ${unidade.sigla}`}
              subtitle={`Lightwall ${unidade.descricao}`}
              content={
                <button id={unidade.sigla} onClick={handleAcesso}>
                  Acessar
                </button>
              }
            />
          ))}
        </section>
      </section>
      <Spinner active={spin} />
    </section>
  );

  async function handleAcesso(event: SyntheticEvent) {
    setSpin(true);
    const btn = event.target as HTMLButtonElement;

    try {
      const unidade = await pcp.post("/login/unidades", {
        unidade: btn.id,
      });
      if (unidade.status !== 204) {
        setSpin(false);
        return alert("Dados de unidade inválidos!");
      }

      setSpin(false);
      navigate("/");
    } catch (error) {
      setSpin(false);

      if (error instanceof AxiosError)
        if (error.response) return alert(JSON.stringify(error.response.data));

      return alert(
        `Ocorreu um erro ao tentar acessar dados de unidade: ${error}`
      );
    }
  }
};
